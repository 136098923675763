<template>
	<div class="bg_dark bg_intro_2">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf003">
						<div class="intro_logo_title">
							<span class="hide">문화예술 전용 커뮤니티 마피아 프로젝트</span>
						</div>
						<div
							v-if="error"
							class="mt-30"
						>
							<p class="error " style="font-size: 1.3em">{{ error }}</p>

							<div class="btn_area mt-30">
								<button
									@click="$emit('to', { name: 'join'})"
									class="btn_m btn_fill_blue"
								>확인 </button>
							</div>
						</div>
					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
						</div>
						<p class="copyright">Copyright © MAFI Inc. </p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'kakao',
	data: function(){
		return {
			code: this.$route.query.code
			, state: this.$route.query.state
			, program: {
				name: '카카오 인증'
				, not_header: true
				, not_footer: true
			}
			, token_info: null
			, kakao_info: null
			, error: ''
		}
	}
	, computed: {
		kakao_return_url: function(){

			let domain = process.env.VUE_APP_DOMAIN
			let dev = process.env.VUE_APP_DEV
			let local = 'https://localhost:8080'
			let location = window.location.href
			let url = ''

			console.log('kakao_return_url location', location)
			if(location.indexOf(domain) > -1){
				url = domain
			}else if(location.indexOf(dev) > -1){
				url = dev
			}else{
				url = local
			}

			return url + '/auth/join/kakao'
		}
	}
	, methods: {
		postJoinWithKakao: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.post_join_social
					, data: {
						member_social_certfc_code: 'MB00400001'
						, social_certfc_code: this.code
						, social_certfc_state_info: this.state
					}
					, type: true
				})

				if(result.success){
					this.token_info = result.data
					console.log('this.token_info', this.token_info)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.error = e
			}finally {
				this.$bus.$emit('on', false)
				/*
				if(process.env.VUE_APP_TYPE == 'sample'){
					const result = await this.$sample.postJoinWithKakao(this.code, this.kakao_return_url)
					console.log('postJoinWithKakao result', result)
					if(result.success){
						this.$emit('to', { name: 'mafia0101'})
					}else{
						this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: result.message}})
					}
				}

				 */
			}
		}
	}

	, created() {
		this.$emit('onLoad', this.program)
		this.postJoinWithKakao()
	}
}
</script>